import React, { PropsWithChildren } from "react";
import { useForm, UseFormMethods, SubmitHandler } from "react-hook-form";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <div className="field">
      <div className="control">
        <input className="input" ref={ref} {...props} />
      </div>
    </div>
  )
);

Input.displayName = "FormPartsInput";

type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { label: string };

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props: PropsWithChildren<TextInputProps>, ref) => (
    <div className="field">
      <label className="label">
        <small>{props.label}</small>
      </label>
      <div className="control">
        <input className="input is-small" type="text" ref={ref} {...props} />
      </div>
      {/*}  <label>
                {errors.nameInput && (
                  <p className="help is-error">This field is required</p>
                )}
              </label> */}
    </div>
  )
);

TextInput.displayName = "FormPartsTextInput";

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & { options: Option[]; label: string };

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props: PropsWithChildren<SelectProps>, ref) => (
    <div className="field">
      <label className="label">
        <small>{props.label}</small>
      </label>

      <div className="control">
        <div className="select is-small">
          <select ref={ref} {...props}>
            {props.options.map(({ label, value }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
);

Select.displayName = "FormPartsSelect";

type FormProps<TFormValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormMethods<TFormValues>) => React.ReactNode;
};

export const Form = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  onSubmit,
  children,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>();
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
  );
};
