import React, { useState } from "react";
import { ADD_ATTACHMENT_MUTATION } from "../queries/AddAttachment";
import { useMutation } from "@apollo/client";
import { Form, Input, Select, TextInput } from "../components/form-parts";
import {
  AddAttachment,
  AddAttachmentVariables,
} from "../queries/types/AddAttachment";
import { CommentInput, CreateShareInput } from "../types/graphql-global-types";
import CsvDropzone from "../components/csv-dropzone";
import { AddComment, AddCommentVariables } from "../queries/types/AddComment";
import { ADD_COMMENT_MUTATION } from "../queries/AddComment";

interface Props {
  shareId: string;
}

/**
 * A visual box that is the mechanism for a user to add a comment.
 *
 * @param props
 * @constructor
 */
const CommentActionBox = (props: Props) => {
  const [
    doAddComment,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<AddComment, AddCommentVariables>(ADD_COMMENT_MUTATION, {
    refetchQueries: ["AShare"],
  });

  const onSubmit = (data: CommentInput) => {
    doAddComment({
      variables: {
        shareId: props.shareId,
        inp: {
          comment: data.comment,
        },
      },
    });
  };

  return (
    <div className="box">
      <div className="content">
        <p>
          <strong>Add Comment</strong>
          <br />
        </p>
      </div>

      <Form<CommentInput> onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <TextInput label="Comment" name="comment" ref={register} />
            <Input type="submit" className="button is-small is-primary" />
          </>
        )}
      </Form>
      {mutationLoading && <p>Loading...</p>}
      {mutationError && (
        <p className="has-text-warning">Error: {mutationError.message}</p>
      )}
    </div>
  );
};

export default CommentActionBox;
