import React, { CSSProperties, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
} as CSSProperties;

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
  color: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
  color: "#ff1744",
};

type Props = {
  disabled?: boolean;
  onFileLoaded(dataurl: string): void;
};

/* A component for drag and drop file uploads using react-dropzone
 * Once a file has been accepted it is converted to a data url ready for posting */
const CsvDropzone = ({ disabled = false, onFileLoaded }: Props) => {
  const [fileName, setFileName] = useState("");
  const [fileMime, setFileMime] = useState("");
  const [fileSize, setFileSize] = useState(0);

  const onDropAccepted = (acceptedFiles: File[]): void => {
    (async (file: File) => {
      const dataurl = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
      console.log(dataurl);

      // the file data itself is send to the parent
      onFileLoaded(dataurl);

      setFileName(file.name);
      setFileMime(file.type);
      setFileSize(file.size);
    })(acceptedFiles[0]);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    accept: ["image/png", "application/pdf", "image/jpeg"],
    multiple: false,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div data-cy="dropzone" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        isDragAccept ? (
          <p>
            <span style={acceptStyle}>Drop image/PDF file here...</span>
          </p>
        ) : (
          <p>
            <span style={rejectStyle}>Please choose a image/PDF file</span>
          </p>
        )
      ) : (
        <>
          {fileSize == null || (fileSize == 0 && <p>image/PDF to attach</p>)}
          {fileSize != null && fileSize > 0 && (
            <p style={{ wordBreak: "break-all" }}>
              {/* long file names with no spaces we just want to break where they can - hence the CSS word-break */}
              {fileName}
              <br />
              {fileMime}
              <br />
              {fileSize} bytes
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default CsvDropzone;
