import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

/* Private route component to protect a route
 * See: https://auth0.com/blog/complete-guide-to-react-user-authentication/ */
const PrivateRoute = ({ component, ...args }: any) => (
  <Route
    component={withAuthenticationRequired(component, {
      // eslint-disable-next-line react/display-name
      onRedirecting: () => (
        <div className="mt-3 has-text-centered">Authenticating...</div>
      ),
      returnTo: "/home",
    })}
    {...args}
  />
);

export default PrivateRoute;
