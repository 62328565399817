import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useQuery } from "@apollo/client";
import {
  AShare,
  AShare_share,
  AShare_share_events,
  AShareVariables,
} from "../queries/types/AShare";
import { A_SHARE_QUERY } from "../queries/AShareQuery";
import { useParams } from "react-router-dom";
import AttachmentActionBox from "./attachment-action-box";
import { ActionEnum, StatusEnum } from "../types/graphql-global-types";
import CommentActionBox from "./comment-action-box";
import ShowHidePdfWrapper from "./pdf-viewer";
import { DateTime } from "luxon";
import classNames from "classnames";
import IdentifyActionBox from "./identify-action-box";

interface TimelineEventProps {
  share: AShare_share;
  ev: AShare_share_events;
}

const PLACER_LAB_COLOUR_CLASS_NAME = "is-info";
const FILLER_LAB_COLOUR_CLASS_NAME = "is-success";

const TimelineEvent = ({ share, ev }: TimelineEventProps) => {
  const when = DateTime.fromISO(ev.when);
  const whenDisplay = when.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

  // we need to choose a colour for the timeline section based on whether the 'doer' is from the filler
  // lab or the placer lab
  const eventIsFromPlacer = ev.whoOrganisationId == share.placerOrganisationId;

  const timelineItemClass = classNames({
    "timeline-item": true,
    [PLACER_LAB_COLOUR_CLASS_NAME]: eventIsFromPlacer,
    [FILLER_LAB_COLOUR_CLASS_NAME]: !eventIsFromPlacer,
  });

  const timelineMarkerClass = classNames({
    "timeline-marker": true,
    "is-image": true,
    "is-48x48": true,
    [PLACER_LAB_COLOUR_CLASS_NAME]: eventIsFromPlacer,
    [FILLER_LAB_COLOUR_CLASS_NAME]: !eventIsFromPlacer,
  });

  const marker = (
    <div className={timelineMarkerClass}>
      <div data-initials={ev.whoPersonDisplayInitials} />
    </div>
  );

  if (ev.what == ActionEnum.IDENTIFIED) {
    return (
      <>
        <div className={timelineItemClass}>
          {marker}
          <div className="timeline-content">
            <p className="heading">{whenDisplay}</p>
            <p>
              Set to
              <br />
              GOS run id: asdad
              <br />
            </p>
          </div>
        </div>
        <header className="timeline-header">
          <span className="tag is-medium is-danger">Identified</span>
        </header>
      </>
    );
  }
  if (ev.what == ActionEnum.COMMENTED) {
    return (
      <div className={timelineItemClass}>
        {marker}
        <div className="timeline-content">
          <p className="heading">{whenDisplay}</p>
          <p>{ev.why}</p>
        </div>
      </div>
    );
  }
  if (ev.what == ActionEnum.CREATED) {
    return (
      <>
        <div className={timelineItemClass}>
          {marker}
          <div className="timeline-content">
            <p className="heading">{whenDisplay}</p>
            <p>
              Asking for
              <br />
              Given name: {share.requestedGivenName}
              <br />
              Family name: {share.requestedFamilyName}
              <br />
              DOB: {share.requestedDOB}
              <br />
              Sample id: {share.requestedSampleId}
              <br />
            </p>
          </div>
        </div>
        <header className="timeline-header">
          <span className="tag is-medium is-danger">Initial</span>
        </header>
      </>
    );
  }
  // TODO: solve toggeleable show/hide https://stackoverflow.com/questions/24502898/show-or-hide-element-in-react
  if (ev.what == ActionEnum.ATTACHED) {
    return (
      <>
        <div className={timelineItemClass}>
          {marker}

          <div className="timeline-content">
            <p className="heading">{whenDisplay}</p>
            <p>{ev.why}</p>

            <ShowHidePdfWrapper shareId={share.shareId} />
          </div>
        </div>
      </>
    );
  }
  return <div>{ev.what}</div>;
};

const Timeline = () => {
  const { shareId } = useParams();

  const { loading, data } = useQuery<AShare, AShareVariables>(A_SHARE_QUERY, {
    variables: { id: shareId },
  });

  if (loading) return <p>Loading..</p>;

  const actions = data ? new Set(data.share.allowedActions) : new Set();

  let eventItems;

  if (data && data.share && data.share.events) {
    // we are not guaranteed a sort order from the return events - so we need to explicitly sort
    const events = [...data.share.events];
    events.sort(
      (a, b) =>
        DateTime.fromISO(b.when).toMillis() -
        DateTime.fromISO(a.when).toMillis()
    );

    eventItems = (
      <>
        {" "}
        {events.map((ev, i) => (
          <TimelineEvent key={i} share={data.share} ev={ev} />
        ))}{" "}
      </>
    );
  } else {
    eventItems = <div>No events</div>;
  }

  const status = data?.share?.status || StatusEnum.INITIAL;
  const statusLevel = [
    StatusEnum.INITIAL,
    StatusEnum.IDENTIFIED,
    StatusEnum.APPROVED,
    StatusEnum.CLOSED,
  ].indexOf(status);

  const initialStateStepClass = classNames({
    "step-item": true,
    "is-completed": statusLevel > 0,
    "is-active": statusLevel === 0,
    "is-danger": true,
  });

  const identifiedStateStepClass = classNames({
    "step-item": true,
    "is-completed": statusLevel > 1,
    "is-active": statusLevel === 1,
    "is-danger": true,
  });

  const approvedStateStepClass = classNames({
    "step-item": true,
    "is-completed": statusLevel > 2,
    "is-active": statusLevel === 2,
    "is-danger": true,
  });

  const closedStateStepClass = classNames({
    "step-item": true,
    "is-completed": statusLevel > 3,
    "is-active": statusLevel === 3,
    "is-danger": true,
  });

  return (
    <div>
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h4 className="title is-4">Summary</h4>
          </div>
          <div className="level-item">
            <p>{data?.share.shareId}</p>
          </div>
        </div>
      </div>

      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <p>A summary of some details of the share</p>
          </div>
        </div>

        <div className="level-right">
          <div className="steps is-small">
            <div className={initialStateStepClass}>
              <div className="step-marker" />
              <div className="step-details">
                <p className="step-title">Initial</p>
              </div>
            </div>
            <div className={identifiedStateStepClass}>
              <div className="step-marker" />
              <div className="step-details">
                <p className="step-title">Identified</p>
              </div>
            </div>
            <div className={approvedStateStepClass}>
              <div className="step-marker" />
              <div className="step-details">
                <p className="step-title">Approved</p>
              </div>
            </div>
            <div className={closedStateStepClass}>
              <div className="step-marker" />
              <div className="step-details">
                <p className="step-title">Closed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="title is-4">Operations</h4>

      <div className="columns">
        {actions.has(ActionEnum.ATTACHED) && (
          <div className="column is-one-quarter">
            <AttachmentActionBox shareId={shareId} />
          </div>
        )}

        {actions.has(ActionEnum.COMMENTED) && (
          <div className="column is-one-quarter">
            <CommentActionBox shareId={shareId} />
          </div>
        )}

        {actions.has(ActionEnum.IDENTIFIED) && (
          <div className="column is-one-quarter">
            <IdentifyActionBox shareId={shareId} />
          </div>
        )}

        {actions.has(ActionEnum.WITHDRAWN) && (
          <div className="column is-one-quarter">
            <div className="box">
              <div className="content">
                <p>
                  <strong>Withdraw</strong>
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean efficitur sit amet massa fringilla egestas. Nullam
                  condimentum luctus turpis.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <hr />

      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h4 className="title is-4">Timeline </h4>
          </div>
          <div className="level-item">
            <p> most recent first</p>
          </div>
        </div>

        <div className="level-right">
          <p>
            <span className={`tag ${PLACER_LAB_COLOUR_CLASS_NAME}`}>
              {data?.share.placerOrganisationDisplayName}
            </span>{" "}
            organisation requesting access
            <span>&nbsp;&nbsp;</span>
            <span className={`tag ${FILLER_LAB_COLOUR_CLASS_NAME}`}>
              {data?.share.fillerOrganisationDisplayName}
            </span>{" "}
            organisation owning data
          </p>
        </div>
      </div>

      <div className="timeline">{eventItems}</div>
    </div>
  );
};

export default Timeline;
