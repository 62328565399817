import React, { useState } from "react";
import { ADD_ATTACHMENT_MUTATION } from "../queries/AddAttachment";
import { useMutation } from "@apollo/client";
import { Form, Input, Select, TextInput } from "../components/form-parts";
import {
  AddAttachment,
  AddAttachmentVariables,
} from "../queries/types/AddAttachment";
import { CreateShareInput } from "../types/graphql-global-types";
import CsvDropzone from "../components/csv-dropzone";

interface Props {
  shareId: string;
}

/**
 * A visual box that is the mechanism for a user to add an attachment.
 *
 * @param props
 * @constructor
 */
const AttachmentActionBox = (props: Props) => {
  const [fileData, setFileData] = useState("");

  const [
    doAddAttachment,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<AddAttachment, AddAttachmentVariables>(
    ADD_ATTACHMENT_MUTATION,
    {
      refetchQueries: ["AShare"],
    }
  );

  const onSubmit = (data: any) => {
    doAddAttachment({
      variables: {
        shareId: props.shareId,
        inp: {
          payload: fileData,
          comment: data.comment,
        },
      },
    });
  };

  return (
    <div className="box">
      <div className="content">
        <p>
          <strong>Add Attachment</strong>
          <br />
          <small>images or PDF, &lt;5MB</small>
        </p>
      </div>

      <CsvDropzone onFileLoaded={setFileData} />

      <Form<CreateShareInput> onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <Select
              label="Purpose"
              name="attachmentPurpose"
              ref={register}
              options={[
                { label: "Consent Form", value: 1 },
                { label: "Test Form", value: 2 },
              ]}
            />
            <TextInput label="Comment" name="comment" ref={register} />
            <Input
              type="submit"
              className="button is-small is-primary"
              disabled={fileData.length === 0}
            />
          </>
        )}
      </Form>
      {mutationLoading && <p>Loading...</p>}
      {mutationError && (
        <p className="has-text-warning">Error: {mutationError.message}</p>
      )}
    </div>
  );
};

export default AttachmentActionBox;
