import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const ADD_ATTACHMENT_MUTATION = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  mutation AddAttachment($shareId: String!, $inp: AttachmentInput!) {
    addAttachment(shareId: $shareId, inp: $inp) {
      ...SharePayloadFragment
    }
  }
`;
