import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const CREATE_SHARE_MUTATION = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  mutation CreateShare($x: CreateShareInput!) {
    createShare(inp: $x) {
      ...SharePayloadFragment
    }
  }
`;
