import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, TextInput } from "../components/form-parts";
import { IdentifyShareableInput } from "../types/graphql-global-types";
import { IDENTIFY_MUTATION } from "../queries/Identify";
import { Identify, IdentifyVariables } from "../queries/types/Identify";

interface Props {
  shareId: string;
}

/**
 * A visual box that is the mechanism for a user to identify a GOS run id.
 *
 * @param props
 * @constructor
 */
const IdentifyActionBox = (props: Props) => {
  const [
    doIdentify,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<Identify, IdentifyVariables>(IDENTIFY_MUTATION, {
    refetchQueries: ["AShare"],
  });

  const onSubmit = (data: IdentifyShareableInput) => {
    doIdentify({
      variables: {
        shareId: props.shareId,
        inp: data,
      },
    });
  };

  return (
    <div className="box">
      <div className="content">
        <p>
          <strong>Identify</strong>
          <br />
        </p>
      </div>

      <Form<IdentifyShareableInput> onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <TextInput label="Run Id" name="runId" ref={register} />
            <Input type="submit" className="button is-small is-primary" />
          </>
        )}
      </Form>
      {mutationLoading && <p>Loading...</p>}
      {mutationError && (
        <p className="has-text-warning">Error: {mutationError.message}</p>
      )}
    </div>
  );
};

export default IdentifyActionBox;
