import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./helpers/auth0-provider-with-history";
import "./helpers/icon-library";
import "./index.css";
import AuthorizedApolloProvider from "./helpers/authorized-apollo-provider";

const root = document.getElementById("root");

if (root != null) {
  // Get required Auth0 data attributes
  const domain = root.dataset.auth0Domain;
  const clientId = root.dataset.auth0ClientId;
  const audience = root.dataset.auth0Audience;

  if (domain && clientId && audience) {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <Auth0ProviderWithHistory
            domain={domain}
            clientId={clientId}
            audience={audience}
          >
            <AuthorizedApolloProvider audience={audience}>
              <App />
            </AuthorizedApolloProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
