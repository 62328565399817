import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const ADD_COMMENT_MUTATION = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  mutation AddComment($shareId: String!, $inp: CommentInput!) {
    addComment(shareId: $shareId, inp: $inp) {
      ...SharePayloadFragment
    }
  }
`;
