/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionEnum {
  APPROVED = "APPROVED",
  ATTACHED = "ATTACHED",
  COMMENTED = "COMMENTED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  DEIDENTIFIED = "DEIDENTIFIED",
  IDENTIFIED = "IDENTIFIED",
  REJECTED = "REJECTED",
  VIEWED = "VIEWED",
  WITHDRAWN = "WITHDRAWN",
}

export enum StatusEnum {
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  IDENTIFIED = "IDENTIFIED",
  INITIAL = "INITIAL",
}

export interface AttachmentInput {
  payload: any;
  comment?: string | null;
}

export interface CommentInput {
  comment: string;
}

export interface CreateShareInput {
  fillerOrganisation: string;
  patientGivenName?: string | null;
  patientFamilyName?: string | null;
  patientAccession?: string | null;
  patientDOB?: string | null;
  sampleAccession?: string | null;
  comment?: string | null;
}

export interface IdentifyShareableInput {
  runId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
