import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const A_SHARE_QUERY = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  query AShare($id: String!) {
    share(shareId: $id) {
      ...SharePayloadFragment
    }
  }
`;
