import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useMutation } from "@apollo/client";
import { GET_ATTACHMENT_MUTATION } from "../queries/GetAttachment";
import {
  GetAttachment,
  GetAttachmentVariables,
  GetAttachment_getAttachment,
} from "../queries/types/GetAttachment";
import { url } from "inspector";

interface NumPages {
  numPages: number;
}

interface ShareProps {
  shareId: string;
}

const ShowHidePdfWrapper = ({ shareId }: ShareProps) => {
  const [showResults, setShowResults] = useState(false);
  const [
    getAttachment,
    { loading: mutationLoading, error: mutationError, data },
  ] = useMutation<GetAttachment, GetAttachmentVariables>(
    GET_ATTACHMENT_MUTATION
  );
  const onClick = () => {
    if (!showResults) {
      getAttachment({ variables: { shareId: shareId } });
    }
    setShowResults(!showResults);
  };
  return (
    <div>
      <input type="submit" value="Show Attachment" onClick={onClick} />
      {showResults && data ? (
        <iframe
          src={data!.getAttachment!.url}
          frameBorder="0"
          height="600px"
          width="100%"
        />
      ) : null}
      {mutationLoading && <p>Loading...</p>}
      {mutationError && (
        <p className="has-text-warning">Error: {mutationError.message}</p>
      )}
    </div>
  );
};

export default ShowHidePdfWrapper;
