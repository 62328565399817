import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { createUploadLink } from "apollo-upload-client";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

interface Props {
  audience: string;
  children?: React.ReactNode;
}

let hasPrintedToken = false;

const AuthorizedApolloProvider = ({ audience, children }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: "/api/private/graphql",
  });

  const uploadLink = createUploadLink({
    uri: "/api/private/graphql",
  });

  const authLink = setContext(async () => {
    try {
      const token = await getAccessTokenSilently({ audience: audience });
      if (!hasPrintedToken) {
        console.log(token);
        hasPrintedToken = true;
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (e) {
      console.log(
        "Failure to get access token so authenticated API calls will fail"
      );
      console.log(e);
      const token = "234242342342342424242";
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: false,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
