/* A library of all the fontawesome icons we need - imported once in index.tsx.
 * Use in any component as follows:
 * import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 * ...
 * const Component = () => {
 * ...
 *   return (
 *      ...
 *      <FontAwesomeIcon icon="sign-out-alt" />
 *      ...
 *   );
 * };
 * */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faUserMd,
  faVial,
} from "@fortawesome/free-solid-svg-icons";

library.add(faSignOutAlt);
library.add(faUserMd);
library.add(faVial);
