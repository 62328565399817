import React from "react";
import labtec from "../assets/img/labtec.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";

/* Sample navigation bar component */
const NavBar = () => {
  // Auth0 hook for user name and logout method
  const { user, logout } = useAuth0();

  return (
    <nav
      className="navbar mb-4 is-light"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item">
          <img src={labtec} className="app-logo" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu">
        <div className="navbar-start">
          <NavLink
            className="navbar-item"
            activeClassName="is-active"
            to="/home"
          >
            Home
          </NavLink>
        </div>
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            {/* User name */}
            <a className="navbar-link">{user.name}</a>
            {/* Logout button */}
            <div className="navbar-dropdown is-right">
              <a
                className="navbar-item"
                onClick={() =>
                  logout({
                    returnTo: window.location.origin,
                  })
                }
              >
                <span className="icon">
                  <FontAwesomeIcon icon="sign-out-alt" />
                </span>
                <span>Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
