import { gql } from "@apollo/client";

export const SHARE_PAYLOAD_FRAGMENT = gql`
  fragment SharePayloadFragment on SharePayload {
    shareId
    lastUpdated
    status
    allowedActions
    fillerOrganisationDisplayName
    fillerOrganisationId
    placerOrganisationId
    placerOrganisationDisplayName
    placerPersonId
    placerPersonDisplayName
    placerPersonDisplayInitials

    events {
      ... on ShareEvent {
        when
        whoPersonId
        whoPersonDisplayName
        whoPersonDisplayInitials
        whoOrganisationId
        whoOrganisationDisplayName
        what
        why
      }
      ... on AttachmentEvent {
        bucket
        filename
        when
        whoPersonId
        whoPersonDisplayName
        whoPersonDisplayInitials
        whoOrganisationId
        whoOrganisationDisplayName
        what
        why
      }
    }

    requestedSampleId
    requestedDOB
    requestedFamilyName
    requestedGivenName
  }
`;
