import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const ALL_SHARES_QUERY = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  query AllShares {
    shares {
      ...SharePayloadFragment
    }
  }
`;
