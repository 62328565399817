import React from "react";
import genovic from "../assets/img/genovic_tagline.png";

/* Sample footer component */
const Footer = () => {
  // Get data attributes if available
  const root = document.getElementById("root");
  const semanticVersion = root!.dataset.semanticVersion || "unspecified";
  const buildVersion = root!.dataset.buildVersion || "unknown";

  return (
    <footer className="footer">
      <div className="is-pulled-left">
        Version: {semanticVersion} Build: {buildVersion}
      </div>
      <div className="is-pulled-right">
        <img src={genovic} width="200px" alt="genovic" />
      </div>
    </footer>
  );
};

export default Footer;
