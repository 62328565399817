import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";

interface Props {
  domain: string;
  clientId: string;
  audience: string;
  children?: React.ReactNode;
}

/* Auth0Provider with access to the application session history
 * See: https://auth0.com/blog/complete-guide-to-react-user-authentication/ */
const Auth0ProviderWithHistory = ({
  domain,
  clientId,
  audience,
  children,
}: Props) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
