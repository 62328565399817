import React from "react";
import { Footer, NavBar } from "../components";
import { Home } from "../views";
import { Route, Switch } from "react-router-dom";
import Timeline from "./timeline";

const Layout = () => {
  // Get required Auth0 data attribute
  const audience = document.getElementById("root")!.dataset.auth0Audience;
  if (!audience) {
    return <div>Missing API audience</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <NavBar />

      <div className="container is-fluid">
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/timeline/:shareId" component={Timeline} />
        </Switch>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
