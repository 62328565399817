import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

/* Sample splash page with Auth0 login redirect */
const Splash = () => {
  // Auth0 hook for login redirect method
  const { loginWithRedirect } = useAuth0();

  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">GenoVic</h1>
          <h2 className="subtitle">Clinical Data Sharing</h2>
          <button className="button" onClick={() => loginWithRedirect()}>
            Start Sharing
          </button>
        </div>
      </div>
    </section>
  );
};

export default Splash;
