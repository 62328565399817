import { gql } from "@apollo/client";
import { SHARE_PAYLOAD_FRAGMENT } from "./SharePayloadFragment";

export const IDENTIFY_MUTATION = gql`
  ${SHARE_PAYLOAD_FRAGMENT}

  mutation Identify($shareId: String!, $inp: IdentifyShareableInput!) {
    identifyShareable(shareId: $shareId, inp: $inp) {
      ...SharePayloadFragment
    }
  }
`;
