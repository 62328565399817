import React from "react";
import { useMutation } from "@apollo/client";
import {
  CreateShare,
  CreateShareVariables,
} from "../queries/types/CreateShare";
import { CREATE_SHARE_MUTATION } from "../queries/CreateShareMutation";
import { Form, Input, Select, TextInput } from "../components/form-parts";
import { CreateShareInput } from "../types/graphql-global-types";

const CreateShareForm = () => {
  const [
    createShare,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<CreateShare, CreateShareVariables>(CREATE_SHARE_MUTATION, {
    refetchQueries: ["AllShares"],
  });

  const onSubmit = (data: CreateShareInput) => {
    console.log(data);

    createShare({ variables: { x: data } });
  };

  return (
    <div>
      <div className="columns">
        <div className="column">
          {/* we can bind our form fields directly to the mutation CreateShareInput class - saves us
                        needing to translate from a FormValues class to the mutation class */}
          <Form<CreateShareInput> onSubmit={onSubmit}>
            {({ register }) => (
              <>
                <h1>Create New Share</h1>
                <Select
                  label="Data Organisation"
                  name="fillerOrganisation"
                  ref={register}
                  options={[
                    { label: "VCGS", value: "https://ror.org/01mmz5j21" },
                    { label: "RMH", value: "https://ror.org/005bvs909" },
                    {
                      label: "Monash Health",
                      value: "https://ror.org/02t1bej08",
                    },
                  ]}
                />
                <TextInput
                  label="Given Name"
                  name="patientGivenName"
                  ref={register}
                />
                <TextInput
                  label="Family Name"
                  name="patientFamilyName"
                  ref={register}
                />
                <TextInput
                  label="Reason/Other Details"
                  name="comment"
                  ref={register}
                />
                <Input type="submit" className="button is-primary" />
              </>
            )}
          </Form>
          {mutationLoading && <p>Loading...</p>}
          {mutationError && (
            <p className="has-text-warning">Error: {mutationError.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateShareForm;
