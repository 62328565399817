import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AllShares } from "../queries/types/AllShares";
import { CREATE_SHARE_MUTATION } from "../queries/CreateShareMutation";
import {
  CreateShare,
  CreateShareVariables,
} from "../queries/types/CreateShare";
import { ALL_SHARES_QUERY } from "../queries/AllSharesQuery";
import CreateShareForm from "./home-create-share-form";
import { NavLink } from "react-router-dom";

interface Props {
  audience: string;
  endpoint: string;
  text: string;
}

const Home = ({ audience, endpoint, text }: Props) => {
  const { loading, data } = useQuery<AllShares>(ALL_SHARES_QUERY);

  if (loading) return <p>Loading..</p>;

  return (
    <div>
      <table className="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Id</th>
            <th>Started</th>
            <th>Status</th>
            <th>Filler</th>
            <th>Placer</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.shares.map((s) => (
              <tr key={s.shareId}>
                <td>{s.shareId}</td>
                <td>{s.lastUpdated}</td>
                <td>{s.status}</td>
                <td>{s.fillerOrganisationDisplayName}</td>
                <td>
                  {s.placerPersonDisplayInitials} @{" "}
                  {s.placerOrganisationDisplayName}
                </td>
                <td>
                  {" "}
                  <NavLink to={`/timeline/${s.shareId}`}>View</NavLink>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <hr />

      <CreateShareForm />
    </div>
  );
};

export default Home;
