import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components";
import { Layout, Splash } from "./views";
import "./app.scss";

const App = () => {
  // Auth0 hook for status flags
  const { isLoading, isAuthenticated } = useAuth0();

  // TODO FIX: this incorrectly briefly renders when the splash screen is mounting

  if (isLoading) {
    // If authenticating, render message
    return <div className="mt-3 has-text-centered">Authenticating...</div>;
  }

  if (!isAuthenticated) {
    // If not authenticated, render splash page
    return <Route path="/" exact component={Splash} />;
  }

  // TODO Understand how the routing works!!!
  // TODO for example, if we go to /foo when authenticated it should redirect to /home

  return (
    <Switch>
      {/* Login bounce */}
      <Route path="/" exact render={() => <Redirect to="/home" />} />
      {/* Any other paths must be authenticated to reach */}
      <PrivateRoute path="/*" component={Layout} />
    </Switch>
  );
};

export default App;
